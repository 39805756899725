import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";
let urutantableSIMURP = 0;
let urutantableIPDMIP = 0;
let urutantableDOISP = 0;

export default {
  data() {
    return {
      search: "",
      loadingTb: false,
      isActive: [],
      options: null,
      summary: [],
      tableSBSN: [],
      tableProyek: [],
      tableLoan: [],
      tableSatker: [],
      tableSIMURP: [],
      tableDOISP: [],
      tableIPDMIP: [],
      table8: [],
      table9: [],
      listStatker: [],
      listBalai: [],
      listGiat: [],
      listEvent: [],
      selectSatker: null,
      isTotalKodeAkun: false,
      isKodeAkunS1: false,
      isKodeAkunS2: false,
      isKodeAkunS3: false,
      istableLoan: false,
      istableSBSN: false,
      istableProyek: false,
      istableIPDMIP: false,
      istableSIMURP: false,
      istableDOISP: false,
      istable8: false,
      istable9: false,
      isShow: false,
      data_color: [
        "#00C5DC",
        "#F4516C",
        "#FFB822",
        "#8859E0",
        "#0C5484",
        "#66BB6A",
        "#00838F",
        "#e57373",
      ],

      listBeban: [],

      currentEdit: {
        key: 0,
        id: "",
        kdbeban: "",
      },
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.$nextTick(() => {
      $(".selectpicker").selectpicker("render");

      this.G_GetRef("balai").then((data) => {
        this.listBalai = data;

        if (this.getUser.userGroup == "balai") {
          this.$refs.balai.setValue(this.getUser.kdbalai);
        } else if (this.getUser.userGroup == "satker") {
          this.$refs.balai.setValue(this.getUser.kdbalai);
        } else {
          this.listBalai.unshift({
            text: "Semua Balai",
            value: "all",
          });
        }
      });

      this.G_GetRef("satker").then((data) => {
        this.listStatker = data;
        if (this.getUser.userGroup == "satker") {
          this.$refs.satker.setValue(this.getUser.kdsatker);
        } else {
          this.listStatker.unshift({
            text: "Semua Satker",
            value: "all",
          });
        }
      });

      this.G_GetRef("giat").then((data) => {
        this.listGiat = data;
      });

      this.G_GetRef("beban").then((data) => {
        this.listBeban = data;
      });

      // this.G_GetAny('sakti/event').then(res => {
      //   let resData = res.data.content

      //   let listData = [];
      //   let arrSub = {};
      //   let setDefault = null

      //   resData.forEach(v => {

      //     if (v.asDefault) {
      //       setDefault = v.saktiEventId
      //     }

      //     arrSub = {};

      //     arrSub['text'] = v.saktiEventNm;
      //     arrSub['value'] = v.saktiEventId;

      //     listData.push(arrSub);
      //   });

      //   this.listEvent = listData;

      //   this.listEvent.unshift({
      //     'text': 'Semua Event',
      //     'value': 'all'
      //   })
      //   this.$refs.event.setValue(setDefault)
      //   this.getSummary()

      // })

      this.getSummary();
    });
  },
  components: {
    myTable,
  },
  computed: {},
  methods: {
    setSatker(id) {
      if (id != "all" || id == null) {
        this.G_GetRef("satker", "?balaiId=" + id).then((data) => {
          this.listStatker = data;
          this.listStatker.unshift({
            text: "Semua Satker",
            value: "all",
          });
        });
      } else {
        this.G_GetRef("satker").then((data) => {
          this.listStatker = data;
          this.listStatker.unshift({
            text: "Semua Satker",
            value: "all",
          });
        });
      }
    },
    toggleSub(key) {
      this.isActive[key] = !this.isActive[key];

      if (this.isActive[key]) {
        $("#sub_" + key).hide();
      } else {
        $("#sub_" + key).show();
      }

    },
    getSummary() {
      this.istableIPDMIP = true;
      this.istableSIMURP = true;
      this.istableDOISP = true;
      this.istable8 = true;
      this.istable9 = true;

      let filter = [];

      if (this.$refs.balai.getValue()) {
        if (this.$refs.balai.getValue() != "all") {
          filter.push("kdbalai=" + this.$refs.balai.getValue());
        }
      }

      // if (this.$refs.event.getValue()) {
      //   if (this.$refs.event.getValue() != 'all') {
      //     filter.push('saktiEventId=' + this.$refs.event.getValue())
      //   }
      // }

      if (this.$refs.satker.getValue()) {
        if (this.$refs.satker.getValue() != "all") {
          filter.push("kdsatker=" + this.$refs.satker.getValue());
        }
      }

      filter.push("saktiEventId=" + this.$route.query.eventId);

      let giat = [];

      if (this.$refs.giat.getValue()) {
        giat.push(this.$refs.giat.getValue());
        let giatL = giat.join(",");
        filter.push("kdgiat=" + giatL);
      }

      filter = "?" + filter.join("&");

      this.G_GetAny("adk/resume/item/invalid-rule" + filter).then((res) => {
        if (res.data.content) {
          this.table8 = res.data.content;
        } else {
          this.table8 = [];
        }
        this.istable8 = false;
      });
      this.G_GetAny("adk/resume/item/invalid-beban" + filter).then((res) => {
        if (res.data.content) {
          this.table9 = res.data.content;
        } else {
          this.table9 = [];
        }
        this.istable9 = false;
      });

      this.isShow = true;
    },
    cariData() {
      this.getSummary();
    },
    detailPaket(kodeSatker) {
      urutan = 0;

      this.G_GetAny(
        "kontraktual/per-paket/nested?kdsatker=" + kodeSatker + "&tahun=2021"
      ).then((res) => {
        this.tableDetailPaket = res.data.content[0].childs[0];

        this.$refs.modal.open(
          "DETAIL PAKET " + this.tableDetailPaket.nmsatker,
          1200
        );
      });
    },

    setNoUrutIPDMIP(idx) {
      urutantableIPDMIP++;
      return urutantableIPDMIP;
    },
    setNoUrutDOISP(idx) {
      urutantableDOISP++;
      return urutantableDOISP;
    },
    setNoUrutSIMURP() {
      urutantableSIMURP++;
      return urutantableSIMURP;
    },
    selectAll(type) {
      if (type == "kolom") {
        this.$refs.colomTable.selectAll();
      } else if (type == "giat") {
        this.$refs.giat.selectAll();
      }
    },
    removeAll(type) {
      if (type == "kolom") {
        this.$refs.colomTable.removeAll();
      } else if (type == "giat") {
        this.$refs.giat.removeAll();
      }
    },

    async openEditKodeBeban(item, kodeBeban) {
      this.$refs.modalEditKodeBeban.open("Edit Kode Beban", 600);

      this.currentEdit.id = item.id;
      this.currentEdit.kdbeban = kodeBeban;
      this.currentEdit.key++;
    },

    async editKodeBeban() {

      let myForm = document.getElementById("formDataBebanEdit");
      let formData = new FormData(myForm);

      formData.append("id", this.currentEdit.id);
      if (this.$refs.bebanedit.getValue()) {
        formData.append(
          "kdbeban",
          this.$refs.bebanedit.getValue().substring(0, 1)
        );
        formData.append(
          "kdjnsban",
          this.$refs.bebanedit.getValue().substring(1, 2)
        );
        formData.append(
          "kdctarik",
          this.$refs.bebanedit.getValue().substring(2, 3)
        );
      }

      this.$refs.modalEditKodeBeban.loading();

      this.G_PutAny(
        "/adk/resume/koreksi/kode-beban/" + this.currentEdit.id,
        formData
      )
        .then((res) => {
          if (!res.status) {
            this.$refs.modalEditKodeBeban.close();
            this.$refs.notif.success(`Berhasil mengupdate kode beban`);
            this.currentEdit.kdbeban = "";
            this.getSummary();
          } else {
            this.$refs.notif.warning("Terjadi Kesalahan");
          }
        })
        .finally(() => {
          this.$refs.modalEditKodeBeban.loadingEnd();
        });
    },
  },
  computed: {
  },
};
